import { httpsCallable } from 'firebase/functions';
import { useMemo } from 'react';
import { useFunctions } from 'reactfire';

export interface Request {
  customerId: string;
  refreshUrl: string;
  returnUrl: string;
  stripeAccountId: string;
  type: 'account_onboarding' | 'account_update';
}

export interface Response {
  url: string;
}

export default function useStripeCreateAccountLink() {
  const functions = useFunctions();
  return useMemo(() => httpsCallable<Request, Response>(functions, 'stripe-createAccountLink'), [functions]);
}
