import {
  Card,
  Link,
  Table,
  TableContainer,
  Td,
  Text,
  Tr,
} from '@chakra-ui/react';
import { Suspense, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { useFirestoreDocData } from 'reactfire';

import Catch from '../../components/Catch';
import { useComplainSnap } from '../../components/ComplainSnapProvider';
import DocumentLoader from '../../components/DocumentLoader';

export default function PropertyCell() {
  const { t } = useTranslation('translations', { keyPrefix: 'ComplainScreen.PropertyCell' });

  const complainSnap = useComplainSnap();
  const complainDoc = useMemo(() => complainSnap.data(), [complainSnap]);

  const { data: propertyDoc } = useFirestoreDocData(complainDoc.propertyRef);

  return (
    <Card h="100%">
      <TableContainer overflowY="auto">
        <Table size="sm" variant="simple">
          <Tr>
            <Td>
              {t('name.label')}
            </Td>

            <Td isNumeric>
              <Link as={RouterLink} to={`../../../properties/${complainDoc.propertyRef.id}`}>
                {propertyDoc.name}
              </Link>
            </Td>
          </Tr>
          <Tr>
            <Td>
              {t('managers.label')}
            </Td>

            <Td isNumeric>
              {propertyDoc.managerRefs.map((managerRef) => (
                <Suspense fallback={null} key={managerRef.id}>
                  {/* eslint-disable-next-line react/no-unstable-nested-components */}
                  <Catch fallback={({ error }) => <Text color="red.200">{error?.message ?? 'Unknown Error'}</Text>}>
                    <DocumentLoader reference={managerRef}>
                      {(managerSnap) => (
                        <Link as={RouterLink} to={`../../users/${managerSnap.id}`}>
                          {managerSnap.data().firstName}
                          {' '}
                          {managerSnap.data().lastName}
                        </Link>
                      )}
                    </DocumentLoader>
                  </Catch>
                </Suspense>
              ))}
            </Td>
          </Tr>
        </Table>
      </TableContainer>
    </Card>
  );
}
