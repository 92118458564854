import {
  Button,
  Grid,
  HStack,
  VStack,
} from '@chakra-ui/react';
import {
  doc,
  DocumentReference,
  serverTimestamp,
  setDoc,
} from 'firebase/firestore';
import { Formik } from 'formik';
import moment from 'moment';
import {
  Suspense,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { PropertyDoc, usePropertiesCollectionRef } from '../../common/collections/Properties';
import { StripeAccountDoc } from '../../common/collections/StripeAccounts';
import { TelegramBotDoc } from '../../common/collections/TelegramBots';
import { UserDoc } from '../../common/collections/Users';
import Currency from '../../common/Currency';
import BooleanFormControl from '../../components/BooleanFormControl';
import { useCustomerSnap } from '../../components/CustomerSnapProvider';
import NumberFormControl from '../../components/NumberFormControl';
import PropertyFinesFormControl from '../../components/PropertyFinesFormControl';
import SelectFormControl from '../../components/SelectFormControl';
import StripeAccountFormControl from '../../components/StripeAccountFormControl';
import TelegramBotFormControl from '../../components/TelegramBotFormControl';
import TextareaFormControl from '../../components/TextareaFormControl';
import TextFormControl from '../../components/TextFormControl';
import UsersFormControl from '../../components/UsersFormControl';
import useShowError from '../../hooks/useShowError';

export type Props = {
  onComplete: (propertyRef: DocumentReference<PropertyDoc>) => void;
};

export default function PropertyCreateForm({
  onComplete,
}: Props) {
  const customerSnap = useCustomerSnap();
  const propertiesCollectionRef = usePropertiesCollectionRef(customerSnap.ref);

  const { t } = useTranslation('translations', { keyPrefix: 'PropertyCreateScreen.PropertyCreateForm' });

  const schema = useMemo(
    () => yup.object().shape({
      address: yup
        .object()
        .label(t('address.label'))
        .required()
        .shape({
          building: yup
            .string()
            .label(t('address.building.label')),
          city: yup
            .string()
            .label(t('address.city.label')),
          country: yup
            .string()
            .label(t('address.country.label')),
          street: yup
            .string()
            .label(t('address.street.label')),
          unit: yup
            .string()
            .label(t('address.unit.label')),
          zip: yup
            .string()
            .label(t('address.zip.label')),
        }),
      comments: yup
        .string()
        .label(t('comments.label')),
      currency: yup
        .string<Currency>()
        .label(t('currency.label'))
        .oneOf(Object.values(Currency))
        .required(),
      fines: yup
        .array(
          yup
            .object()
            .shape({
              amount: yup
                .number()
                .min(0)
                .label(t('fines.item.amount.label'))
                .required(),
              name: yup
                .string()
                .label(t('fines.item.name.label'))
                .required(),
            })
            .label(t('fines.item.label'))
            .required(),
        )
        .label(t('fines.label'))
        .required(),
      isActive: yup
        .boolean()
        .label(t('isActive.label'))
        .required(),
      managerRefs: yup
        .array()
        .label(t('managerRefs.label'))
        .required()
        .min(1)
        .of(
          yup
            .mixed<DocumentReference<UserDoc>>()
            .label(t('managerRefs.item.label'))
            .required(),
        ),
      name: yup
        .string()
        .label(t('name.label'))
        .required(),
      paymentDateShift: yup
        .number()
        .min(-28)
        .max(28)
        .label(t('paymentDateShift.label'))
        .required(),
      privacyPolicyUrl: yup
        .string()
        .label(t('privacyPolicyUrl.label'))
        .url()
        .required(),
      stripeAccountRef: yup
        .mixed<DocumentReference<StripeAccountDoc>>()
        .label(t('stripeAccountRef.label'))
        .required()
        .nullable(),
      telegramBotRef: yup
        .mixed<DocumentReference<TelegramBotDoc>>()
        .label(t('telegramBotRef.label'))
        .required()
        .nullable(),
      timezone: yup
        .string()
        .label(t('timezone.label'))
        .required(),
    }),
    [t],
  );

  const initialValues = useMemo<typeof schema['__outputType']>(
    () => ({
      address: {
        building: '',
        city: 'Warsaw',
        country: 'Poland',
        street: '',
        zip: '',
      },
      comments: '',
      currency: Currency.PLN,
      fines: [],
      isActive: true,
      managerRefs: [],
      name: '',
      paymentDateShift: 0,
      privacyPolicyUrl: '',
      stripeAccountRef: null,
      telegramBotRef: null,
      timezone: moment.tz.guess(),
    }),
    [],
  );

  const showError = useShowError();

  const handleFormSubmit = useCallback(
    async (values: typeof schema['__outputType']) => {
      try {
        const propertyRef = doc(propertiesCollectionRef);

        if (!values.telegramBotRef || !values.stripeAccountRef) {
          return;
        }

        await setDoc(
          propertyRef,
          {
            _v: 1,
            address: values.address,
            comments: values.comments,
            createdAt: serverTimestamp(),
            currency: values.currency,
            fines: values.fines ?? [],
            isActive: values.isActive,
            managerRefs: values.managerRefs,
            name: values.name,
            paymentDateShift: values.paymentDateShift ?? 0,
            privacyPolicyUrl: values.privacyPolicyUrl,
            stripeAccountRef: values.stripeAccountRef,
            telegramBotRef: values.telegramBotRef,
            timezone: values.timezone,
            updatedAt: serverTimestamp(),
          },
        );

        onComplete(propertyRef);
      } catch (err) {
        showError(err);
      }
    },
    [onComplete, propertiesCollectionRef, showError],
  );

  const [validateAll, setValidateAll] = useState<boolean>(false);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validateOnBlur={validateAll}
      validateOnChange={validateAll}
      validationSchema={schema}
    >
      {({
        handleSubmit,
        isSubmitting,
      }) => (
        <VStack
          alignItems="stretch"
          as="form"
          noValidate
          onSubmit={(e) => {
            setValidateAll(true);
            e.preventDefault();
            handleSubmit();
          }}
          spacing={10}
        >
          <VStack gap={2}>
            <BooleanFormControl
              label={t('isActive.label')}
              name="isActive"
            />

            <TextFormControl
              isRequired
              label={t('name.label')}
              name="name"
            />

            <HStack gap={4} width="100%">
              <SelectFormControl
                isRequired
                label={t('timezone.label')}
                name="timezone"
                options={moment.tz.names().reduce((r, tz) => ({ ...r, [tz]: tz }), {})}
              />

              <TextFormControl
                isRequired
                label={t('privacyPolicyUrl.label')}
                name="privacyPolicyUrl"
              />
            </HStack>

            <Grid gap={4} templateColumns="repeat(3, 1fr)" width="100%">
              <TextFormControl
                label={t('address.country.label')}
                name="address.country"
              />

              <TextFormControl
                label={t('address.city.label')}
                name="address.city"
              />

              <TextFormControl
                label={t('address.zip.label')}
                name="address.zip"
              />

              <TextFormControl
                label={t('address.street.label')}
                name="address.street"
              />

              <TextFormControl
                label={t('address.building.label')}
                name="address.building"
              />

              <TextFormControl
                label={t('address.unit.label')}
                name="address.unit"
              />
            </Grid>

            <HStack alignContent="flex-end" alignItems="flex-end" gap={4} w="100%">
              <NumberFormControl
                isRequired
                label={t('paymentDateShift.label')}
                max={28}
                min={-28}
                name="paymentDateShift"
                step={1}
              />

              <SelectFormControl
                isRequired
                label={t('currency.label')}
                name="currency"
                options={Currency}
              />
            </HStack>

            <HStack gap={4} w="100%">
              <Suspense fallback={null}>
                <TelegramBotFormControl
                  isRequired
                  label={t('telegramBotRef.label')}
                  name="telegramBotRef"
                />
              </Suspense>

              <Suspense fallback={null}>
                <StripeAccountFormControl
                  isRequired
                  label={t('stripeAccountRef.label')}
                  name="stripeAccountRef"
                />
              </Suspense>
            </HStack>

            <TextareaFormControl
              label={t('comments.label')}
              name="comments"
            />
          </VStack>

          <Suspense fallback={null}>
            <UsersFormControl
              isRequired
              label={t('managerRefs.label')}
              name="managerRefs"
            />
          </Suspense>

          <PropertyFinesFormControl amountLabel={t('fines.item.amount.label')} label={t('fines.label')} name="fines" nameLabel={t('fines.item.name.label')} />

          <Button
            isLoading={isSubmitting}
            loadingText={t('createButton.loading')}
            type="submit"
          >
            {t('createButton.default')}
          </Button>
        </VStack>
      )}
    </Formik>
  );
}
