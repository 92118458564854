import { doc } from 'firebase/firestore';
import React, { useMemo } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { useFirestoreDoc } from 'reactfire';

import { useStripeAccountsCollectionRef } from '../../common/collections/StripeAccounts';
import { useCustomerSnap } from '../../components/CustomerSnapProvider';
import StripeAccountSnapProvider from '../../components/StripeAccountSnapProvider';

export type Props = {
  fallback: React.ReactElement;
};

export default function StripeAccountSnapLayout({ fallback }: Props) {
  const { stripeAccountId } = useParams<{ stripeAccountId: string }>();

  const customerSnap = useCustomerSnap();

  const stripeAccountsCollectionRef = useStripeAccountsCollectionRef(customerSnap.ref);
  const stripeAccountRef = useMemo(
    () => doc(stripeAccountsCollectionRef, stripeAccountId),
    [stripeAccountId, stripeAccountsCollectionRef],
  );

  const { data: stripeAccountSnap } = useFirestoreDoc(stripeAccountRef);

  if (!stripeAccountSnap.exists()) {
    return fallback;
  }

  return (
    <StripeAccountSnapProvider snap={stripeAccountSnap}>
      <Outlet />
    </StripeAccountSnapProvider>
  );
}
