import { ThemeTypings } from '@chakra-ui/react';
import { useMemo } from 'react';

import { ContractStatus } from '../common/collections/Contracts';

const useContractStatusColorScheme = (status: ContractStatus): ThemeTypings['colorSchemes'] => useMemo(
  () => {
    switch (status) {
      case ContractStatus.DRAFT: {
        return 'gray';
      }
      case ContractStatus.AWAITING_SIGNATURE: {
        return 'blue';
      }
      case ContractStatus.AWAITING_PAYMENT: {
        return 'yellow';
      }
      case ContractStatus.CANCELLED:
      case ContractStatus.TERMINATED: {
        return 'red';
      }
      case ContractStatus.SUSPENDED: {
        return 'orange';
      }
      case ContractStatus.ACTIVE: {
        return 'green';
      }
      default: {
        return 'pink';
      }
    }
  },
  [status],
);

export default useContractStatusColorScheme;
