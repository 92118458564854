import {
  Card,
  CardHeader,
  Heading,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { query } from 'firebase/firestore';
import { useTranslation } from 'react-i18next';
import { useFirestoreCollection } from 'reactfire';

import { useStripeAccountsCollectionRef } from '../../common/collections/StripeAccounts';
import { useCustomerSnap } from '../../components/CustomerSnapProvider';
import StripeAccountRow from './StripeAccountRow';

export default function StripeAccountsScreen() {
  const customerSnap = useCustomerSnap();
  const stripeAccountsCollectionRef = useStripeAccountsCollectionRef(customerSnap.ref);

  const { data: stripeAccountsSnap } = useFirestoreCollection(
    query(
      stripeAccountsCollectionRef,
    ),
  );

  const { t } = useTranslation('translations', { keyPrefix: 'StripeAccountsScreen' });

  return (
    <Card h="100%">
      <CardHeader p={4}>
        <Heading fontSize="lg">
          {t('title')}
        </Heading>
      </CardHeader>

      <TableContainer overflowY="auto">
        <Table size="sm" variant="simple">
          <Thead>
            <Tr>
              <Th>{t('tableHeader.name')}</Th>
              <Th w={0} />
            </Tr>
          </Thead>
          <Tbody>
            {stripeAccountsSnap.docs.map((stripeAccountSnap) => (
              <StripeAccountRow key={stripeAccountSnap.id} stripeAccountSnap={stripeAccountSnap} />
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Card>
  );
}
