/* eslint-disable import/no-cycle */
import {
  collection,
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  QueryDocumentSnapshot,
  Timestamp,
} from 'firebase/firestore';
import { useMemo } from 'react';
import Stripe from 'stripe';

import { CustomerDoc, isCustomerRef } from './Customers';

export type StripeAccountDoc = {
  _v: 1;
  account: Stripe.Account;
  createdAt: Timestamp;
  name: string;
  updatedAt: Timestamp;
};

export const isStripeAccountDoc = (
  doc?: DocumentData,
): doc is StripeAccountDoc => true;

export const isStripeAccountRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<StripeAccountDoc> => ref.parent.id === 'stripeAccounts' && ref.parent.parent !== null && isCustomerRef(ref.parent.parent);

function isStripeAccountSnap(
  snap: QueryDocumentSnapshot
): snap is QueryDocumentSnapshot<StripeAccountDoc>;
function isStripeAccountSnap(
  snap: DocumentSnapshot,
): snap is DocumentSnapshot<StripeAccountDoc>;
function isStripeAccountSnap(
  snap: DocumentSnapshot | QueryDocumentSnapshot,
): snap is DocumentSnapshot<StripeAccountDoc> | QueryDocumentSnapshot<StripeAccountDoc> {
  return isStripeAccountRef(snap.ref);
}

export { isStripeAccountSnap };

export const getStripeAccountsCollectionRef = (customerRef: DocumentReference<CustomerDoc>): CollectionReference<StripeAccountDoc> => collection(customerRef, 'stripeAccounts') as CollectionReference<StripeAccountDoc>;

export const useStripeAccountsCollectionRef = (
  customerRef: DocumentReference<CustomerDoc>,
) => useMemo(() => getStripeAccountsCollectionRef(customerRef), [customerRef]);
