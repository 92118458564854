import {
  HStack,
  Icon,
  IconButton,
  Td,
  Tooltip,
  Tr,
} from '@chakra-ui/react';
import { QueryDocumentSnapshot } from 'firebase/firestore';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { LuEye, LuPenSquare } from 'react-icons/lu';
import { Link } from 'react-router-dom';

import { StripeAccountDoc } from '../../common/collections/StripeAccounts';

export type Props = {
  stripeAccountSnap: QueryDocumentSnapshot<StripeAccountDoc>;
};

export default function StripeAccountRow({ stripeAccountSnap }: Props) {
  const stripeAccountDoc = useMemo(() => stripeAccountSnap.data(), [stripeAccountSnap]);

  const { t } = useTranslation('translations', { keyPrefix: 'StripeAccountsScreen.StripeAccountRow' });

  return (
    <Tr>
      <Td>
        {stripeAccountDoc.name}
      </Td>

      <Td>
        <HStack>
          <Tooltip label={t('viewButton.default')}>
            <IconButton
              aria-label={t('viewButton.default')}
              as={Link}
              icon={<Icon as={LuEye} />}
              size="xs"
              to={stripeAccountSnap.id}
            />
          </Tooltip>

          <Tooltip label={t('editButton.default')}>
            <IconButton
              aria-label={t('editButton.default')}
              as={Link}
              icon={<Icon as={LuPenSquare} />}
              size="xs"
              to={`${stripeAccountSnap.id}/edit`}
            />
          </Tooltip>
        </HStack>
      </Td>
    </Tr>
  );
}
