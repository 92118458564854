import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Card,
  CardBody,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  IconButton,
  Tooltip,
  VStack,
} from '@chakra-ui/react';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { LuCable, LuPenSquare } from 'react-icons/lu';
import { Link, useNavigate } from 'react-router-dom';

import { useCustomerSnap } from '../../components/CustomerSnapProvider';
import { useStripeAccountSnap } from '../../components/StripeAccountSnapProvider';
import useStripeCreateAccountLink from '../../functions/useStripeCreateAccountLink';

export default function StripeAccountScreen() {
  const { t: nav } = useTranslation('translations', { keyPrefix: 'Navigation' });
  const { t } = useTranslation('translations', { keyPrefix: 'StripeAccountScreen' });

  const navigate = useNavigate();

  const onEditClick = useCallback(
    () => {
      navigate('edit');
    },
    [navigate],
  );

  const customerSnap = useCustomerSnap();

  const stripeAccountSnap = useStripeAccountSnap();
  const stripeAccountDoc = useMemo(() => stripeAccountSnap.data(), [stripeAccountSnap]);

  const stripeCreateAccountLink = useStripeCreateAccountLink();
  const onStripeOnboardingButton = useCallback(
    () => {
      stripeCreateAccountLink({
        customerId: customerSnap.id,
        refreshUrl: window.location.href,
        returnUrl: window.location.href,
        stripeAccountId: stripeAccountSnap.id,
        type: 'account_onboarding',
      })
        .then(({ data }) => {
          window.open(data.url, '_blank');
        })
        .catch(() => { });
    },
    [
      customerSnap.id,
      stripeAccountSnap.id,
      stripeCreateAccountLink,
    ],
  );

  return (
    <Grid gap={4} h="100%" templateRows="auto 1fr">
      <GridItem>
        <Card>
          <CardBody p={4}>
            <VStack alignItems="stretch">
              <Breadcrumb>
                <BreadcrumbItem>
                  <BreadcrumbLink as={Link} to="..">{nav('stripeAccounts.title')}</BreadcrumbLink>
                </BreadcrumbItem>

                <BreadcrumbItem isCurrentPage>
                  <BreadcrumbLink as={Link} to=".">{stripeAccountDoc.name}</BreadcrumbLink>
                </BreadcrumbItem>
              </Breadcrumb>

              <HStack>
                <Heading flex={1} fontSize="3xl" h={10}>
                  {stripeAccountDoc.name}
                </Heading>

                {
                  stripeAccountDoc.account.requirements?.currently_due?.length
                    || stripeAccountDoc.account.requirements?.past_due?.length
                    ? (
                      <Button
                        leftIcon={<Icon as={LuCable} />}
                        onClick={onStripeOnboardingButton}
                      >
                        {t('stripeOnboardingButton.default')}
                      </Button>
                    )
                    : null
                }

                <Tooltip label={t('editButton.default')}>
                  <IconButton
                    aria-label={t('editButton.default')}
                    icon={<Icon as={LuPenSquare} />}
                    onClick={onEditClick}
                  />
                </Tooltip>
              </HStack>
            </VStack>
          </CardBody>
        </Card>
      </GridItem>

      <GridItem overflow="auto" />
    </Grid>
  );
}
