import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Select,
  Text,
} from '@chakra-ui/react';
import { doc, DocumentReference } from 'firebase/firestore';
import { useField } from 'formik';
import { ChangeEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useFirestoreCollection } from 'reactfire';

import {
  StripeAccountDoc,
  useStripeAccountsCollectionRef,
} from '../common/collections/StripeAccounts';
import useShowError from '../hooks/useShowError';
import { useCustomerSnap } from './CustomerSnapProvider';

export interface Props {
  // eslint-disable-next-line react/require-default-props
  isRequired?: boolean;
  label: string;
  name: string;
}

export default function StripeAccountFormControl({
  isRequired = false,
  label,
  name,
}: Props) {
  const { t } = useTranslation('translations', { keyPrefix: 'StripeAccountFormControl' });

  const showError = useShowError();
  const customerSnap = useCustomerSnap();
  const stripeAccountsCollectionRef = useStripeAccountsCollectionRef(customerSnap.ref);

  const {
    data: stripeAccountsCollectionSnap,
  } = useFirestoreCollection(stripeAccountsCollectionRef);

  const [input, meta, helper] = useField<DocumentReference<StripeAccountDoc> | null>(name);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      helper.setValue(
        e.target.value.length ? doc(stripeAccountsCollectionRef, e.target.value) : null,
      )
        .catch(showError);
    },
    [helper, stripeAccountsCollectionRef, showError],
  );

  return (
    <FormControl isInvalid={!!meta.error} isRequired={isRequired}>
      <FormLabel>{label}</FormLabel>

      {stripeAccountsCollectionSnap.docs.length ? (
        <Select onChange={handleChange} value={input.value?.id ?? undefined}>
          <option value="">-</option>
          {stripeAccountsCollectionSnap.docs.map((propertySnap) => (
            <option
              key={propertySnap.id}
              value={propertySnap.id}
            >
              {propertySnap.data().name}
            </option>
          ))}
        </Select>
      ) : (
        <Box
          alignItems="center"
          border={1}
          borderColor="inherit"
          borderRadius="md"
          borderStyle="solid"
          display="flex"
          fontSize="md"
          h={10}
          px={4}
        >
          <Text flex={1}>{t('empty')}</Text>
          <Button as={Link} size="xs" target="_blank" to={`/${customerSnap.id}/admin/stripeAccounts/new`}>
            {t('createButton.default')}
          </Button>
        </Box>
      )}

      <FormErrorMessage>
        {meta.error}
      </FormErrorMessage>
    </FormControl>
  );
}
