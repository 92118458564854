import React from 'react';
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';

import AnnouncementCreateScreen from './screens/AnnouncementCreateScreen';
import AnnouncementsScreen from './screens/AnnouncementsScreen';
import AuthLayout from './screens/AuthLayout';
import CheckAuthLayout from './screens/CheckAuthLayout';
import ComplainScreen from './screens/ComplainScreen';
import ComplainSnapLayout from './screens/ComplainSnapLayout';
import ComplainsScreen from './screens/ComplainsScreen';
import ContractCreateScreen from './screens/ContractCreateScreen';
import ContractScreen from './screens/ContractScreen';
import ContractSnapLayout from './screens/ContractSnapLayout';
import ContractsScreen from './screens/ContractsScreen';
import ContractUpdateScreen from './screens/ContractUpdateScreen';
import CustomerSnapLayout from './screens/CustomerSnapLayout';
import InvoiceScreen from './screens/InvoiceScreen';
import InvoiceSnapLayout from './screens/InvoiceSnapLayout';
import InvoicesScreen from './screens/InvoicesScreen';
import LoginScreen from './screens/LoginScreen';
import MainLayout from './screens/MainLayout';
import PropertiesScreen from './screens/PropertiesScreen';
import PropertyCreateScreen from './screens/PropertyCreateScreen';
import PropertyRedirectScreen from './screens/PropertyRedirectScreen';
import PropertyScreen from './screens/PropertyScreen';
import PropertySnapLayout from './screens/PropertySnapLayout';
import PropertyUpdateScreen from './screens/PropertyUpdateScreen';
import StripeAccountCreateScreen from './screens/StripeAccountCreateScreen';
import StripeAccountScreen from './screens/StripeAccountScreen';
import StripeAccountSnapLayout from './screens/StripeAccountSnapLayout';
import StripeAccountsScreen from './screens/StripeAccountsScreen';
import StripeAccountUpdateScreen from './screens/StripeAccountUpdateScreen';
import TelegramBotCreateScreen from './screens/TelegramBotCreateScreen';
import TelegramBotScreen from './screens/TelegramBotScreen';
import TelegramBotSnapLayout from './screens/TelegramBotSnapLayout';
import TelegramBotsScreen from './screens/TelegramBotsScreen';
import TelegramBotUpdateScreen from './screens/TelegramBotUpdateScreen';
import TenantCreateScreen from './screens/TenantCreateScreen';
import TenantScreen from './screens/TenantScreen';
import TenantSnapLayout from './screens/TenantSnapLayout';
import TenantsScreen from './screens/TenantsScreen';
import TenantUpdateScreen from './screens/TenantUpdateScreen';
import UnitCreateScreen from './screens/UnitCreateScreen';
import UnitScreen from './screens/UnitScreen';
import UnitSnapLayout from './screens/UnitSnapLayout';
import UnitsScreen from './screens/UnitsScreen';
import UnitUpdateScreen from './screens/UnitUpdateScreen';
import UserCreateScreen from './screens/UserCreateScreen';
import UserScreen from './screens/UserScreen';
import UserSnapLayout from './screens/UserSnapLayout';
import UsersScreen from './screens/UsersScreen';
import UserUpdateScreen from './screens/UserUpdateScreen';

export default function MainRouter() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<CustomerSnapLayout fallback={<Navigate replace to="../404" />} />} path=":customerId">
          <Route element={<CheckAuthLayout fallback={<Navigate to="login" />} />}>
            <Route element={<MainLayout />}>

              <Route element={<Navigate replace to="properties" />} index />

              <Route path="properties">
                <Route element={<PropertyRedirectScreen />} index />

                <Route element={<PropertySnapLayout fallback={<Navigate replace to="../404" />} />} path=":propertyId">
                  <Route element={<Navigate replace to="contracts" />} index />

                  <Route path="announcements">
                    <Route element={<AnnouncementsScreen />} index />
                    <Route element={<AnnouncementCreateScreen />} path="new" />
                  </Route>

                  <Route path="units">
                    <Route element={<UnitsScreen />} index />
                    <Route element={<UnitCreateScreen />} path="new" />

                    <Route element={<UnitSnapLayout fallback={<Navigate replace to="../404" />} />} path=":unitId">
                      <Route element={<UnitScreen />} index />
                      <Route element={<UnitUpdateScreen />} path="edit" />
                    </Route>
                  </Route>

                  <Route path="tenants">
                    <Route element={<TenantsScreen />} index />
                    <Route element={<TenantCreateScreen />} path="new" />

                    <Route element={<TenantSnapLayout fallback={<Navigate replace to="../404" />} />} path=":tenantId">
                      <Route element={<TenantScreen />} index />
                      <Route element={<TenantUpdateScreen />} path="edit" />
                    </Route>
                  </Route>

                  <Route path="invoices">
                    <Route element={<InvoicesScreen />} index />

                    <Route element={<InvoiceSnapLayout fallback={<Navigate replace to="../404" />} />} path=":invoiceId">
                      <Route element={<InvoiceScreen />} index />
                    </Route>
                  </Route>

                  <Route path="contracts">
                    <Route element={<ContractsScreen />} index />
                    <Route element={<ContractCreateScreen />} path="new" />

                    <Route element={<ContractSnapLayout fallback={<Navigate replace to="../404" />} />} path=":contractId">
                      <Route element={<ContractScreen />} index />
                      <Route element={<ContractUpdateScreen />} path="edit" />
                    </Route>
                  </Route>
                </Route>
              </Route>

              <Route path="admin">
                <Route element={<Navigate replace to="properties" />} index />

                <Route path="properties">
                  <Route element={<PropertiesScreen />} index />
                  <Route element={<PropertyCreateScreen />} path="new" />

                  <Route element={<PropertySnapLayout fallback={<Navigate replace to="../404" />} />} path=":propertyId">
                    <Route element={<PropertyScreen />} index />
                    <Route element={<PropertyUpdateScreen />} path="edit" />
                  </Route>
                </Route>

                <Route path="complains">
                  <Route element={<ComplainsScreen />} index />

                  <Route element={<ComplainSnapLayout fallback={<Navigate replace to="../404" />} />} path=":complainId">
                    <Route element={<ComplainScreen />} index />
                  </Route>
                </Route>

                <Route path="users">
                  <Route element={<UsersScreen />} index />
                  <Route element={<UserCreateScreen />} path="new" />

                  <Route element={<UserSnapLayout fallback={<Navigate replace to="../404" />} />} path=":userId">
                    <Route element={<UserScreen />} index />
                    <Route element={<UserUpdateScreen />} path="edit" />
                  </Route>
                </Route>

                <Route path="telegramBots">
                  <Route element={<TelegramBotsScreen />} index />
                  <Route element={<TelegramBotCreateScreen />} path="new" />

                  <Route element={<TelegramBotSnapLayout fallback={<Navigate replace to="../404" />} />} path=":telegramBotId">
                    <Route element={<TelegramBotScreen />} index />
                    <Route element={<TelegramBotUpdateScreen />} path="edit" />
                  </Route>
                </Route>

                <Route path="stripeAccounts">
                  <Route element={<StripeAccountsScreen />} index />
                  <Route element={<StripeAccountCreateScreen />} path="new" />

                  <Route element={<StripeAccountSnapLayout fallback={<Navigate replace to="../404" />} />} path=":stripeAccountId">
                    <Route element={<StripeAccountScreen />} index />
                    <Route element={<StripeAccountUpdateScreen />} path="edit" />
                  </Route>
                </Route>
              </Route>
            </Route>
          </Route>

          <Route element={<AuthLayout />}>
            <Route element={<LoginScreen />} path="login" />
          </Route>
        </Route>

        <Route element={<Navigate to="/wowhomes" />} path="/" />
      </Routes>
    </BrowserRouter>
  );
}
