import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Card,
  CardBody,
  Center,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  IconButton,
  Spinner,
  Tooltip,
  VStack,
} from '@chakra-ui/react';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { LuPlusSquare } from 'react-icons/lu';
import { Link } from 'react-router-dom';

import StripeAccountsTable from './StripeAccountsTable';

export default function StripeAccountsScreen() {
  const { t } = useTranslation('translations', { keyPrefix: 'StripeAccountsScreen' });
  const { t: nav } = useTranslation('translations', { keyPrefix: 'Navigation' });

  return (
    <Grid gap={4} h="100%" templateRows="auto 1fr">
      <GridItem>
        <Card>
          <CardBody p={4}>
            <VStack alignItems="stretch">
              <Breadcrumb>
                <BreadcrumbItem isCurrentPage>
                  <BreadcrumbLink as={Link} to=".">{nav('stripeAccounts.title')}</BreadcrumbLink>
                </BreadcrumbItem>
              </Breadcrumb>

              <HStack>
                <Heading flex={1} fontSize="3xl" h={10}>
                  {t('title')}
                </Heading>

                <Tooltip label={t('createButton.default')}>
                  <IconButton
                    aria-label={t('createButton.default')}
                    as={Link}
                    icon={<Icon as={LuPlusSquare} />}
                    to="new"
                  />
                </Tooltip>
              </HStack>

            </VStack>
          </CardBody>
        </Card>
      </GridItem>

      <GridItem overflow="auto">
        <Suspense fallback={<Center h="100%"><Spinner /></Center>}>
          <StripeAccountsTable />
        </Suspense>
      </GridItem>
    </Grid>
  );
}
