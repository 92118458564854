import { httpsCallable } from 'firebase/functions';
import { useMemo } from 'react';
import { useFunctions } from 'reactfire';

export interface Request {
  country: string;
  customerId: string;
  name: string;
  refreshUrl: string;
  returnUrl: string;
}

export interface Response {
  stripeAccountId: string;
  url: string;
}

export default function useStripeCreateAccount() {
  const functions = useFunctions();
  return useMemo(() => httpsCallable<Request, Response>(functions, 'stripe-createAccount'), [functions]);
}
