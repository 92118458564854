/* eslint-disable import/no-cycle */
import {
  collection,
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  QueryDocumentSnapshot,
  Timestamp,
} from 'firebase/firestore';
import { useMemo } from 'react';

import { CustomerDoc, isCustomerRef } from './Customers';
import { PropertyDoc } from './Properties';
import { TenantDoc } from './Tenants';

export type ComplainDoc = {
  _v: 1;
  createdAt: Timestamp;
  message: string;
  propertyRef: DocumentReference<PropertyDoc>;
  tenantRef: DocumentReference<TenantDoc>;
  updatedAt: Timestamp;
};

export const isComplainDoc = (
  doc?: DocumentData,
): doc is ComplainDoc => true;

export const isComplainRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<ComplainDoc> => ref.parent.id === 'complains' && ref.parent.parent !== null && isCustomerRef(ref.parent.parent);

function isComplainSnap(
  snap: QueryDocumentSnapshot
): snap is QueryDocumentSnapshot<ComplainDoc>;
function isComplainSnap(
  snap: DocumentSnapshot,
): snap is DocumentSnapshot<ComplainDoc>;
function isComplainSnap(
  snap: DocumentSnapshot | QueryDocumentSnapshot,
): snap is DocumentSnapshot<ComplainDoc> | QueryDocumentSnapshot<ComplainDoc> {
  return isComplainRef(snap.ref);
}

export { isComplainSnap };

export const getComplainsCollectionRef = (customerRef: DocumentReference<CustomerDoc>) => collection(customerRef, 'complains') as CollectionReference<ComplainDoc>;

export const useComplainsCollectionRef = (
  customerRef: DocumentReference<CustomerDoc>,
) => useMemo(() => getComplainsCollectionRef(customerRef), [customerRef]);
