import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Card,
  CardBody,
  Grid,
  GridItem,
  Heading,
  HStack,
  VStack,
} from '@chakra-ui/react';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import { useStripeAccountSnap } from '../../components/StripeAccountSnapProvider';
import StripeAccountUpdateForm from './StripeAccountUpdateForm';

export default function StripeAccountUpdateScreen() {
  const { t: nav } = useTranslation('translations', { keyPrefix: 'Navigation' });

  const navigate = useNavigate();

  const onComplete = useCallback(
    () => {
      navigate('../', { replace: true });
    },
    [navigate],
  );

  const stripeAccountSnap = useStripeAccountSnap();
  const stripeAccountDoc = useMemo(() => stripeAccountSnap.data(), [stripeAccountSnap]);

  return (
    <Grid gap={4} h="100%" templateRows="auto 1fr">
      <GridItem>
        <Card>
          <CardBody p={4}>
            <VStack alignItems="stretch">
              <Breadcrumb>
                <BreadcrumbItem>
                  <BreadcrumbLink as={Link} to="../..">{nav('stripeAccounts.title')}</BreadcrumbLink>
                </BreadcrumbItem>

                <BreadcrumbItem>
                  <BreadcrumbLink as={Link} to="..">{stripeAccountDoc.name}</BreadcrumbLink>
                </BreadcrumbItem>

                <BreadcrumbItem isCurrentPage>
                  <BreadcrumbLink as={Link} to=".">{nav('stripeAccounts.update.title')}</BreadcrumbLink>
                </BreadcrumbItem>
              </Breadcrumb>

              <HStack>
                <Heading flex={1} fontSize="3xl" h={10}>
                  {stripeAccountDoc.name}
                </Heading>
              </HStack>
            </VStack>
          </CardBody>
        </Card>
      </GridItem>

      <GridItem overflow="auto">
        <StripeAccountUpdateForm onComplete={onComplete} stripeAccountSnap={stripeAccountSnap} />
      </GridItem>
    </Grid>
  );
}
